import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@sashka/theme';
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import { Typography } from '@sashka/ui-lib';
import { searchProducts, ProductSearchResult } from './../../api/search';
import Image from 'gatsby-image';
import { Link } from 'gatsby';

interface SearchProps {
  isOpen: boolean;
  closeSearch: () => void;
}

const variants = {
  visible: {
    opacity: 1,
    height: '100%',
  },
  hidden: {
    opacity: 0,
    height: 0,
  },
};

export const Wrapper = styled(motion.section)<{ theme: Theme }>(({ theme }) => ({
  fill: theme.palette.text.contrast,

  background: theme.palette.background.beta,
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Container = styled(motion.section)<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SearchInput = styled(motion.input)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.h3,
  color: theme.palette.text.contrast,
  background: 'transparent',
  border: 'none',
  borderBottom: `1px solid ${theme.palette.text.contrast}`,
  outline: 'none',
}));

export const Search: React.FunctionComponent<SearchProps> = ({ isOpen, closeSearch }) => {
  const [searchString, setSearchString] = React.useState<string>('');
  const [searchResults, setSearchResults] = React.useState<ProductSearchResult[]>([]);

  React.useEffect(() => {
    if (!searchString) {
      return;
    }
    const timerID = setTimeout(async () => {
      const { data } = await searchProducts(searchString);
      setSearchResults(data);
    }, 500);

    return () => clearTimeout(timerID);
  }, [searchString]);

  const productPick = () => {
    setSearchString('');
    setSearchResults([]);
    closeSearch();
  };

  return (
    <AnimateSharedLayout>
      <Wrapper {...{ variants, animate: isOpen ? 'visible' : 'hidden', initial: 'hidden' }}>
        <Container>
          <Typography {...{ variant: 'h3', color: 'contrast' }}>What are you looking for?</Typography>
          <SearchInput {...{ value: searchString, onChange: e => setSearchString(e.target.value) }} />
          <StyledSearchResults>
            {searchResults.map(result => {
              return <SearchResult {...{ key: result.id, result, closeSearch: productPick }} />;
            })}
          </StyledSearchResults>
        </Container>
      </Wrapper>
    </AnimateSharedLayout>
  );
};

export default Search;

export const StyledSearchResults = styled(motion.ul)<{ theme: Theme }>(({ theme }) => ({
  listStyle: 'none',
  maxWidth: theme.breakpoints.sm,
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: theme.gutters.base,
}));

export const StyledSearchResult = styled(motion.li)<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  gridGap: theme.gutters.base * 2,
  cursor: 'pointer',
  alignItems: 'center',
}));

const SearchResult: React.FunctionComponent<{
  result: ProductSearchResult;
  closeSearch: SearchProps['closeSearch'];
}> = ({ result, closeSearch }) => {
  return (
    <AnimatePresence>
      <Link {...{ to: `/product/${result.handle}`, onClick: closeSearch }}>
        <StyledSearchResult {...{ initial: { opacity: 0 }, animate: { opacity: 1 }, style: {} }}>
          <Image
            {...{
              fluid: {
                src: result.image,
                srcSet: result.image,
                sizes: '100',
                aspectRatio: 1.5,
              },
            }}
          />
          <div>
            <Typography {...{ style: { gridArea: 'title' } }}>{result.title}</Typography>
            <Typography>{result.price}</Typography>
          </div>
        </StyledSearchResult>
      </Link>
    </AnimatePresence>
  );
};
