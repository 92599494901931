import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@sashka/theme';
import WithLabel from '../WithLabel/WithLabel';
import Tooltip from '../Tooltip/Tooltip';

interface Option {
  key: string | number;
  value: string;
  label: string;
}

interface SelectProps {
  options: Option[];
  label: string;
  onChange: (option: Option) => void;
  defaultValue?: string;
}

const StyledSelect = styled('ul')<{ theme?: Theme }>(({ theme }) => ({
  ...theme.typography.meta.body2,
  margin: 0,
  padding: 0,
  listStyle: 'none',
}));

const StyledOption = styled('li')<{ theme?: Theme }>(({ theme }) => ({
  cursor: 'pointer',
  margin: theme.gutters.base,
}));

export const Select: React.FunctionComponent<SelectProps> = ({ onChange, options, defaultValue, label }) => {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(
    defaultValue ? options.findIndex((opt) => opt.value === defaultValue) : 0,
  );

  const handleClick = (e: any) => {
    const ind = e.target.value;
    setSelectedIndex(e.target.value);
    onChange(options[ind]);
  };

  return (
    <WithLabel {...{ label }}>
      <Tooltip {...{ button: <div>{options[selectedIndex].label}</div> }}>
        <StyledSelect {...{ onClick: handleClick }}>
          {options.map((option, ind) => {
            return <StyledOption {...{ key: option.key, value: ind }}>{option.label}</StyledOption>;
          })}
        </StyledSelect>
      </Tooltip>
    </WithLabel>
  );
};

export default Select;
