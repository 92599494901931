import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@sashka/theme';
import logo from './../../images/logo.png';

interface LogoProps {
  src?: string;
  alt?: string;
  maxWidth?: React.CSSProperties['maxWidth'];
}

const StyledLogo = styled('img')<{ theme: Theme; maxWidth: LogoProps['maxWidth'] }>(({ theme, maxWidth }) => ({
  display: 'flex',
  flex: 1,
  width: maxWidth ?? '100%',
  cursor: 'pointer',
}));

export const Logo: React.FunctionComponent<LogoProps> = ({ src = logo, alt = 'The Sashka Studios Logo', maxWidth }) => {
  return (
    <div>
      <StyledLogo {...{ src, alt, maxWidth }} />
    </div>
  );
};

export default Logo;
