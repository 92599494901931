import * as React from 'react';

import { Link } from 'react-router-dom';

import { motion, useCycle } from 'framer-motion';
import { useDimensions } from '@sashka/hooks';
import styled from '@emotion/styled';
import MenuToggle from './MenuToggle';
import MenuItems, { MenuItemMeta } from './MenuItems';

import { HamburgerIcon } from '@sashka/icons';
import { Theme } from '@sashka/theme';
import { StaticQuery, graphql, useStaticQuery } from 'gatsby';
// import styled from '@emotion/styled';

export interface MenuProps {
  items?: MenuItemMeta[];
  isMenuOpen?: boolean;
  toggleMenu?: () => void;
}

const MenuToggleButton = styled(HamburgerIcon)<{ theme: Theme }>(({ theme }) => ({
  // position: 'fixed',
  top: theme.gutters.base * 4,
  left: theme.gutters.base * 4,
  cursor: 'pointer',
  zIndex: theme.zIndex.drawer + 10,
}));

const styledNavVariants = {
  open: { x: 0 },
  closed: { x: '-200%' },
};

const StyledNav = styled(motion.nav)<{ theme: Theme; height?: number }>(({ theme, height = '100vh' }) => ({
  position: 'fixed',
  overflowX: 'hidden',
  overflowY: 'auto',
  top: 0,
  left: 0,
  bottom: 0,
  padding: '2rem 4rem 2rem 2rem ',
  maxWidth: 300,
  background: theme.palette.background.alpha,
  zIndex: theme.zIndex.drawer,
}));

export const Menu: React.FunctionComponent<MenuProps> = ({ items, isMenuOpen, toggleMenu }) => {
  const [isOpen, setOpen] = React.useState(false);
  const containerRef = React.useRef(null);

  return (
    <div>
      <MenuToggleButton {...{ width: 32, color: 'disabled', onClick: () => setOpen(!isOpen) }} />
      <StyledNav
        {...{
          // ref: containerRef,
          // height,
          initial: false,
          variants: styledNavVariants,
          animate: isOpen ? 'open' : 'closed',
        }}
      >
        <MenuItems {...{ menuItems: items, toggleMenu: () => setOpen(!isOpen), isOpen }} />
      </StyledNav>
    </div>
  );
};

export default () => {
  const data = useStaticQuery(graphql`
    {
      allShopifyCollection {
        edges {
          node {
            id
            title
            description
            handle
            image {
              src
            }
          }
        }
      }
    }
  `);

  const items = data.allShopifyCollection.edges.map(({ node }) => {
    return {
      key: node.id,
      handle: node.handle,
      text: node.title,
    };
  });

  return (
    <Menu
      {...{
        items,
      }}
    />
  );
};
