// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-templates-collection-page-collection-page-tsx": () => import("./../../../src/templates/CollectionPage/CollectionPage.tsx" /* webpackChunkName: "component---src-templates-collection-page-collection-page-tsx" */),
  "component---src-templates-product-page-product-page-tsx": () => import("./../../../src/templates/ProductPage/ProductPage.tsx" /* webpackChunkName: "component---src-templates-product-page-product-page-tsx" */)
}

