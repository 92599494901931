import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@sashka/theme';
import { CheckboxBaseProps } from './Checkbox.types';
import Typography from '../Typography/Typography';

const CheckboxWrapper = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  // styles
}));

const CheckboxMark = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  // styles
}));

const CheckmarkEmpty = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  // styles
}));

const CheckmarkIcon = styled('div')({
  // styles
});

export const CheckboxInput = styled('input')<{ theme?: Theme }>(({ theme }) => ({
  // styles
}));

const CheckboxLabel: React.FunctionComponent = ({ children }) => (
  <Typography {...{ variant: 'label', component: 'label', textOpacity: 1 }}>{children}</Typography>
);

export const CheckboxBase: React.FunctionComponent<CheckboxBaseProps> = ({
  label,
  checked,
  name,
  onChange,
  value,
  emptyIcon = <CheckmarkEmpty />,
  checkedIcon = <CheckmarkIcon />,
}) => {
  return (
    <CheckboxWrapper {...{ onChange }}>
      <CheckboxMark {...{ checked }}>{checked ? checkedIcon : emptyIcon}</CheckboxMark>
      <CheckboxInput {...{ type: 'checkbox' }}></CheckboxInput>
      {label && <CheckboxLabel>{label}</CheckboxLabel>}
    </CheckboxWrapper>
  );
};
