import React from 'react';

interface ClickAwayListenerProps {
  onClickAway: () => void;
}

const ClickAwayListener: React.FunctionComponent<ClickAwayListenerProps> = ({ onClickAway, children }) => {
  const node = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickAway = (event: MouseEvent) => {
      if (!node.current?.contains(event.target as Node)) {
        onClickAway();
      }
    };
    document.addEventListener('click', handleClickAway);
    return () => document.removeEventListener('click', handleClickAway);
  }, []);

  return <div ref={node}>{children}</div>;
};

export default ClickAwayListener;
