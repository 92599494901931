import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@sashka/theme';
import Label from '../Label/Label';

export interface LabelProps {
  label: string;
  labelProps?: object;
}

const StyledWithLabel = styled('label')<{ theme?: Theme }>(({ theme }) => ({
  display: 'grid',
  gridRowGap: 0,
  gridColumnGap: theme.gutters.base * 2,
  gridTemplateColumns: 'max-content max-content',
  gridTemplateRows: '1fr 0px',
  alignItems: 'center',
}));

export const WithLabel: React.FunctionComponent<LabelProps> = ({ label, labelProps, children }) => {
  return (
    <StyledWithLabel>
      <Label {...{ label, ...labelProps }} />
      {children}
    </StyledWithLabel>
  );
};

export default WithLabel;
