import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@sashka/theme';

export interface CardProps {
  /**
   * Should apply special styling for selected card item?
   * @default true
   */
  isSelected?: boolean;
  /**
   * If you'd like to have rounded corners
   * @default true
   */
  rounded?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  transparent?: boolean;
  withHover?: boolean;

  zIndex?: number;
  hoverMode?: boolean;

  /**
   * Whether it's `grid` or `inline-grid`
   * @default 'false';
   */
  inline?: boolean;
}

export const Wrap = styled('div')<
  { theme?: Theme; clickable: boolean } & Pick<
    CardProps,
    'withHover' | 'transparent' | 'isSelected' | 'onClick' | 'rounded' | 'zIndex' | 'hoverMode' | 'inline'
  >
>(
  ({
    theme,
    clickable,
    isSelected = true,
    rounded = true,
    transparent,
    withHover,
    hoverMode,
    zIndex,
    inline = false,
  }) => ({
    borderRadius: rounded ? theme.gutters.base * 2 : 0,
    // boxShadow: isSelected ? theme.shadows.c : 'none',
    // background: transparent ? 'transparent' : theme.palette.white,
    cursor: clickable ? 'pointer' : 'initial',
    padding: theme.gutters.base * 2,
    display: inline ? 'inline-grid' : 'grid',
    gridGap: theme.gutters.base * 2,
    ...(hoverMode && {
      // background: theme.palette.white,
      // boxShadow: theme.shadows.c,
      cursor: 'pointer',
    }),
    ...(withHover && {
      '&:hover': {
        // background: theme.palette.white,
        // boxShadow: theme.shadows.c,
        cursor: 'pointer',
      },
    }),
    ...(zIndex && { zIndex }),
  }),
);

export const Card: React.FunctionComponent<CardProps> = ({
  onClick,
  isSelected,
  rounded,
  children,
  className,
  transparent,
  withHover,
  zIndex,
  hoverMode,
  inline,
}) => {
  return (
    <Wrap
      {...{
        hoverMode,
        className,
        withHover,
        zIndex,
        isSelected,
        rounded,
        clickable: Boolean(onClick),
        onClick,
        transparent,
        inline,
      }}
    >
      {children}
    </Wrap>
  );
};

export default Card;
