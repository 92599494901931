import * as React from 'react';
import { SVGIcon, SVGIconProps } from '@sashka/ui-lib';

export const HamburgerIcon: React.FunctionComponent<SVGIconProps> = ({
  width,
  height,
  color = 'primary',
  viewBox,
  ...rest
}) => (
  <SVGIcon
    {...{
      width,
      height,
      color,
      viewBox,
      ...rest,
    }}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M4 18h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z" />
  </SVGIcon>
);

export default HamburgerIcon;
