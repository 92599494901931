import * as React from 'react';
import styled from '@emotion/styled';

import { Theme, TypographyVariant } from '@sashka/theme';

interface TypographyProps {
  /**
   * How would you like it to render? Please note, that the default is `p` unless stated otherwise.
   * If you need h1, passing only the variant is not enough.
   * @default 'p'
   */
  component?: keyof HTMLElementTagNameMap;
  /**
   * @default body1
   */
  variant?: TypographyVariant;

  /**
   * sets font-weight 600
   * @default false
   */
  bold?: boolean;

  /**
   * sets font-weight 700
   * @default false
   */
  bolder?: boolean;

  /**
   * If true, no margins will be applied
   * @default false
   */
  noMargin?: boolean;

  /**
   * Align text to center
   */
  center?: boolean;

  /**
   * Imagine you'd like to add some opacity to the text.
   * @default undefined
   */
  textOpacity?: number;

  /**
   * In case you would like to override the color of the text.
   * @default main
   */
  color?: keyof Theme['palette']['text'];

  /**
   * Margin override
   */
  margin?: React.CSSProperties['margin'];
}

// function isTextColor(color: TypographyProps['color']): color is keyof Theme['palette']['text'] {
//   return color === 'main' || color === 'contrast';
// }

const Typo = styled('p')<
  { theme?: Theme } & Pick<
    TypographyProps,
    'center' | 'noMargin' | 'variant' | 'bold' | 'textOpacity' | 'color' | 'margin'
  >
>(
  ({
    theme,
    variant = 'body1',
    bold = false,
    noMargin = false,
    center = false,
    textOpacity,
    color = 'main',
    margin,
  }) => {
    return {
      fontFamily: theme.typography.fontFamily,
      //   color: isTextColor(color) ? theme.palette.text[color] : theme.palette.indicators[color],
      ...(theme.typography?.meta[variant] ?? {}),
      margin: margin ? margin : noMargin ? 0 : 'initial',
      textAlign: center ? 'center' : 'initial',
      color: theme.palette.text[color],
      ...(textOpacity && { opacity: textOpacity }),
    };
  },
);

export const Typography: React.FunctionComponent<TypographyProps> = ({
  component = 'p',
  variant = 'body1',
  bold,
  textOpacity,
  children,

  ...rest
}) => {
  return <Typo {...{ as: component, variant, bold, textOpacity, ...rest }}>{children}</Typo>;
};

export default Typography;
