import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';

import ContextProvider from './../provider/ContextProvider';

import { WithTheme } from '@sashka/wrappers';
import { createTheme, Theme } from '@sashka/theme';
import { GlobalStyle } from '../utils/styles';
import Navigation from '../components/Navigation/Navigation';
import FooterComponent from './../components/Footer/Footer';
import { MotionConfig, AnimationFeature } from 'framer-motion';

const theme = createTheme({
  theme: {
    palette: {
      main: {
        primary: '#ED9B40',
        secondary: '#61C9A8',
        cta: '#BA3B46',
      },
      background: {
        alpha: '#88ccff',
        beta: '#ffa700',
        // site: '#FFEEDB',
        site: '#FFf',
      },
    },
    typography: {
      fontFamily: 'Roboto',
    },
    radius: {
      tooltip: 8,
    },
  },
});

export const Wrapper = styled('section')<{ theme: Theme }>(({ theme }) => {
  return {
    width: '100%',
    background: theme.palette.background.site,
    marginBottom: '1.45rem',
    padding: '0 0.5rem',
    overflow: 'hidden',
  };
});

export const Layout = styled('div')<{ theme: Theme }>(({ theme }) => {
  return {
    padding: 0,
    width: '100%',
    minHeight: '100vh',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: `1fr`,
    gridTemplateRows: 'min-content 1fr min-content',
    gridGap: theme.gutters.base * 0,
    background: theme.palette.background.site,
    // overflow: 'hidden',
  };
});

export const Header = styled('header')<{ theme: Theme }>(({ theme }) => {
  return {
    width: '100%',
  };
});

export const Content = styled('main')<{ theme: Theme }>(({ theme }) => {
  return {
    // padding: '5rem 0',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    // padding: '0 1rem',
  };
});
export const Footer = styled('footer')<{ theme: Theme }>(({ theme }) => {
  return {
    // height: 100,
    borderTop: `1px solid ${theme.palette.main.disabled}`,
    width: '100%',
  };
});

// const Wrapper = styled.div`
//   margin: 0 auto;
//   max-width: 960px;
//   padding: 0px 1.0875rem 1.45rem;
// `;

const MainLayout = ({ children }) => {
  return (
    <WithTheme {...{ theme: theme }}>
      {/* <MotionConfig features={[AnimationFeature]}> */}
      <ContextProvider>
        <GlobalStyle />
        <StaticQuery
          query={graphql`
            query SiteTitleQuery {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={data => (
            <Layout>
              <Header>
                <Navigation siteTitle={data.site.siteMetadata.title} />
              </Header>
              <Content>{children}</Content>
              <Footer>
                <FooterComponent />
              </Footer>
            </Layout>
          )}
        />
      </ContextProvider>
      {/* </MotionConfig> */}
    </WithTheme>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
