import React from 'react';

import { MemoryRouter, Router } from 'react-router-dom';
// import history from 'src/utils/history/history-utils'

export const WithRouterForTesting: React.FunctionComponent = ({ children }) => {
  return <MemoryRouter>{children}</MemoryRouter>;
};

const history: any = [];

export const WithRouterForApp: React.FunctionComponent = ({ children }) => {
  return <Router {...{ history }}>{children}</Router>;
};
