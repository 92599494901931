import * as React from 'react';
import styled from '@emotion/styled';

import { usePopper } from 'react-popper';

import { observer } from 'mobx-react';
import { motion, AnimatePresence } from 'framer-motion';
import ClickAwayListener from './ClickAwayListener';
import { Theme } from '@sashka/theme';
import Card from '../Card/Card';

export interface TooltipProps {
  onClose?: () => void;
  toggleFunc?: () => void;
  button?: React.ReactElement;
  isRTL?: boolean;
  open?: boolean;
  placement?: 'right' | 'top' | 'left' | 'bottom';
  /** @default true */
  selfClosing?: boolean;
  anchorEl?: any; // React.RefObject;
  strategy?: 'absolute' | 'fixed';
}

export const Popper = styled(motion.div)<{ theme?: Theme }>(({ theme }) => ({
  zIndex: theme.zIndex.tooltip,
  border: '1px solid black',
  background: 'white',
  padding: 0,
  borderRadius: theme.radius.tooltip,
  overflow: 'hidden',
}));

export const StyledCard = styled(Card)<{ theme?: Theme }>(({ theme }) => ({
  padding: 0,
  textAlign: 'center',
  '& ul': { padding: 0, margin: 0 },
  '& li': {
    padding: theme.gutters.base,
    margin: 0,
    '&:hover': {
      background: theme.palette.background.delta,
    },
  },
}));

export const Tooltip: React.FunctionComponent<TooltipProps> = ({
  isRTL,
  placement,
  toggleFunc,
  button,
  open,
  children,
  selfClosing = true,
  anchorEl,
  strategy = 'absolute',
}) => {
  const [buttonRef, setButtonRef] = React.useState(null);
  const [tooltipRef, setTooltipRef] = React.useState(null);

  const isControlled = typeof open === 'boolean';

  const [show, setShow] = React.useState<boolean>(isControlled ? Boolean(open) : false);

  const { styles, attributes } = usePopper(buttonRef || anchorEl, tooltipRef, {
    placement: placement ? placement : isRTL ? 'left' : 'right',
    strategy,
  });

  React.useEffect(() => {
    if (!buttonRef || !tooltipRef) {
      return;
    }
    isControlled && setShow(Boolean(open));
  }, [open]);

  const visible = isControlled ? Boolean(open) : show;

  const handleClick = () => {
    isControlled && toggleFunc && toggleFunc();
    !isControlled && setShow(!show);
  };

  const onClickAway = () => {
    isControlled && toggleFunc && toggleFunc();
    !isControlled && selfClosing && setShow(false);
    setShow(false);
  };

  if (!button && !anchorEl) {
    return null;
  }

  return (
    <div>
      {button &&
        React.cloneElement(button, {
          ref: setButtonRef,
          type: 'button',
          onClick: handleClick,
          style: { cursor: 'pointer' },
        })}
      <AnimatePresence>
        {visible && (
          // @ts-ignore
          <Popper
            {...{
              transition: { duration: 0.2 },
              initial: { opacity: 0 },
              exit: { opacity: 0 },
              animate: { opacity: 1 },
              ref: setTooltipRef,
              style: { ...styles.popper },
              ...attributes.popper,
            }}
          >
            <ClickAwayListener {...{ onClickAway }}>
              <StyledCard
                {...{
                  rounded: true,
                  isSelected: true,
                  onClick: (e: any) => {
                    e.stopPropagation();
                    selfClosing && setShow(false);
                  },
                }}
              >
                {children}
              </StyledCard>
            </ClickAwayListener>
          </Popper>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Tooltip;
