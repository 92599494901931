import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@sashka/theme';

interface ListProps { }

export const StyledList = styled('ul')<{ theme?: Theme }>(({ theme }) => ({
  listStyle: 'none',
  margin: 0,
  padding: 0,
}));

export const List: React.FunctionComponent<ListProps> = React.forwardRef<HTMLUListElement, ListProps>(
  ({ children }, ref) => {
    return <StyledList {...{ ref }}>{children}</StyledList>;
  }
);

export default List;
