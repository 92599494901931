module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/alex/dev/sashka/monorepo/projects/ecom/gatsby/src/layouts/MainLayout.tsx"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"thesashka-demo","short_name":"thesashka-demo","start_url":"/","background_color":"#88ccff","theme_color":"#88ccff","display":"minimal-ui","icon":"src/images/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f0999b82f85db5136f34d3c76fc01fba"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
