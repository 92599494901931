import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { theme as defaultTheme, Theme } from '@sashka/theme';

interface WithThemeProps {
  theme?: Theme;
}

export const WithTheme: React.FunctionComponent<WithThemeProps> = ({ theme = defaultTheme, children }) => {
  // console.log('in @sashka/wrappers theme', theme);
  return <ThemeProvider {...{ theme }}>{React.Children.only(children)}</ThemeProvider>;
};
