import { PartialDeep } from 'type-fest';
import { Typography, typography } from './typography';

type MainPalette = 'primary' | 'secondary' | 'disabled' | 'cta' | 'border' | 'current';
type IndicatorsPalette = 'success' | 'warning' | 'error';
type TextColor = 'main' | 'contrast';
type BackgroundPalette ='site' | 'alpha' | 'beta' | 'gamma' | 'delta';
type Breakpoints = 'xs'|'sm' | 'md'|'lg' | 'xl'

interface Palette {
  main: Record<MainPalette, string>;
  indicators: Record<IndicatorsPalette, string>;
  text: Record<TextColor, string>;
  background: Record<BackgroundPalette, string>;
}

type ZIndexKeys = 'appBar' | 'drawer' | 'modal' | 'snackbar' | 'tooltip';

type Radius = 'button' | 'card' | 'circle' | 'tooltip';

type Border = 'checkbox' | 'dashed';

type Opacity = 'hidden' | 'half' | 'visible';

/**
 * Basic theme we will use to build components from.
 */
export interface Theme {
  palette: Palette;
  zIndex: Record<ZIndexKeys, number>;
  gutters: Gutters;
  radius: Record<Radius, number>;
  border: Record<Border, string>;
  opacity: Record<Opacity, number>;
  typography: Typography;
  breakpoints: Record<Breakpoints, number>
}

interface Gutters {
  base: number;
}

/**
 * Components Theme which has specific overrides for specific components.
 */
interface ComponentsTheme {}

type CreateThemeOptions = { theme?: PartialDeep<Theme> }

export const createTheme: (createThemeOptions?: CreateThemeOptions) => Theme = ({theme  } = {}) => ({
  palette: {
    main: {
      primary: 'coral',
      secondary: 'yellow',
      border: '#ccc',
      cta: 'green',
      disabled: '#333',
      current: 'currentColor',
      ...theme?.palette?.main,
    },
    indicators: {
      success: 'green',
      warning: 'orange',
      error: 'red',
      ...theme?.palette?.indicators,
    },
    text: { main: 'black', contrast: 'white' },
    background: {
      site: '#fff',
      alpha: '#999',
      beta: '#666',
      gamma: '#666',
      delta: '#ccc',
      ...theme?.palette.background
    },
  },
  zIndex: {
    appBar: 600,
    modal: 700,
    drawer: 800,
    snackbar: 900,
    tooltip: 1000,
    ...theme?.zIndex,
  },
  border: {
    checkbox: '1px solid #ccc',
    dashed: '1px dashed #ccc',
    ...theme?.border,
  },
  opacity: {
    hidden: 0,
    half: 0.5,
    visible: 1,
    ...theme?.opacity,
  },
  gutters: { base: 8, ...theme?.gutters },
  radius: {
    button: 20,
    card: 12,
    circle: 50,
    tooltip: 0,
    ...theme?.radius,
  },
  // typography: { ...typography(), ...(theme?.typography && {...theme.typography }) },
  typography: { ...typography(),}, 
  breakpoints: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 },
});

export const theme = createTheme();

