import React, { useContext } from 'react';
import reduce from 'lodash/reduce';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { CartIcon, SearchIcon } from '@sashka/icons';
import logo from './../../images/logo.png';

import StoreContext from '~/context/StoreContext';
import {
  // CartCounter,
  // Container,
  MenuLink,
  //  Wrapper
} from './styles';

import { Theme } from '@sashka/theme';
import { Typography } from '@sashka/ui-lib';
import Logo from '../Logo/Logo';
import Menu from '../Menu/Menu';
import Search from '../Search/Search';

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);
  const items = checkout ? checkout.lineItems : [];
  const total = reduce(items, (acc, item) => acc + item.quantity, 0);
  return [total !== 0, total];
};

export const Wrapper = styled('section')<{ theme: Theme }>(({ theme }) => {
  return {
    width: '100%',
    background: theme.palette.background.beta,
    // background: 'transparent',
    position: 'absolute',
    zIndex: theme.zIndex.appBar,
    // marginBottom: '1.45rem',
  };
});

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => {
  return {
    // width: '100%',
    // background: theme.palette.background.alpha,
    // background: 'transparent',
    // position: 'absolute',
    // marginBottom: '1.45rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr max-content',
    // display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1rem',
  };
});

export const StyledLink = styled(Link)<{ theme: Theme }>(({ theme }) => {
  return {
    textDecoration: 'none',
    color: 'white',
  };
});

export const CartContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'baseline',
  flexDirection: 'row-reverse',
}));

export const CartCounter = styled('div')<{ theme: Theme }>(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.contrast,
    backgroundColor: theme.palette.background.alpha,
    borderRadius: '50%',
    width: theme.gutters.base * 2,
    height: theme.gutters.base * 2,
  };
});

export const IconsContainer = styled('div')<{ theme: Theme }>(({ theme }) => {
  return {
    display: 'grid',
    // gridTemplateColumns: 'repeat(auto-fit, minmax(50px, min-content))',
    gridTemplateColumns: 'repeat(2, max-content)',
    gap: theme.gutters.base * 2,
    alignItems: 'center',
  };
});

export const StyledCartIcon = styled(CartIcon)<{ theme: Theme }>(({ theme }) => {
  return {
    fill: theme.palette.text.contrast,
    width: theme.gutters.base * 3,
  };
});

export const StyledSearchIcon = styled(SearchIcon)<{ theme: Theme }>(({ theme }) => {
  return {
    fill: theme.palette.text.contrast,
    width: theme.gutters.base * 3,
    cursor: 'pointer',
  };
});

const Navigation = ({ siteTitle }) => {
  const [hasItems, quantity] = useQuantity();
  const [isSearchOpen, setSearchOpen] = React.useState<boolean>(false);
  console.log('Navigation -> isSearchOpen!', isSearchOpen);

  return (
    <Wrapper>
      <Container>
        <Menu />
        <StyledLink {...{ to: '/' }}>
          <Logo {...{ maxWidth: 75, alt: `{siteTitle} | logo` }} />
          {/* <Typography>{siteTitle}</Typography> */}
        </StyledLink>
        <IconsContainer>
          <StyledSearchIcon {...{ onClick: () => setSearchOpen(!isSearchOpen) }} />
          <StyledLink {...{ to: '/cart' }}>
            <CartContainer>
              {hasItems && <CartCounter>{quantity}</CartCounter>}
              <StyledCartIcon {...{}} />
            </CartContainer>
          </StyledLink>
        </IconsContainer>
      </Container>
      <Search {...{ isOpen: isSearchOpen, closeSearch: () => setSearchOpen(false) }} />
    </Wrapper>
  );
};

Navigation.propTypes = {
  siteTitle: PropTypes.string,
};

Navigation.defaultProps = {
  siteTitle: ``,
};

export default Navigation;
