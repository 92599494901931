export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  // 'h5' | 'h6'  |
  | 'body1'
  | 'body2'
  | 'caption'
  | 'label'
  | 'menuItem';

interface TypoMeta
  extends Pick<
    React.CSSProperties,
    | 'fontSize'
    | 'fontWeight'
    | 'lineHeight'
    | 'letterSpacing'
    | 'textTransform'
    | 'textDecoration'
    | 'textAlign'
    | 'color'
  > {}

export interface Typography {
  imports: string[];
  fontFamily: string;
  meta: Record<TypographyVariant, TypoMeta>;
}

export const typography = (): Typography => ({
  imports: ['https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,700;1,300&display=swap'],
  fontFamily: `'Roboto', sans-serif`,
  meta: {
    h1: {
      fontSize: '2.875rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2.25rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.1875rem',
      lineHeight: 'normal',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    caption: {
      fontSize: '0.875rem',
    },
    label: {
      fontSize: '0.875rem',
      // fontWeight: 'bold',
    },
    menuItem: {
      fontSize: '0.875rem',
      fontWeight: 'normal',
    },
  },
});
