import * as React from 'react';
import styled from '@emotion/styled';
import { m as motion } from 'framer-motion';
import { Typography } from '@sashka/ui-lib';
import { Theme } from '@sashka/theme';
import { Link } from 'gatsby';

import Logo from '../Logo/Logo';
//import styled from '@emotion/styled';

interface MenuItemsProps {
  menuItems: MenuItemMeta[];
  toggleMenu: () => void;
}

export interface MenuItemMeta {
  key: string;
  handle: string;
  text: string;
}

const menuItemVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const menuItemsVariants = {
  open1: {
    y: 0,
    // x: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed1: {
    y: 50,
    // x: '-100%',
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Wrapper = styled(motion.div)({
  // position: 'absolute',
  // top: 100,
  // width: '100%',
  // padding: 25,
  // zIndex: 42,
  //   listStyle: 'none',
});

const StyledList = styled('ul')<{ theme: Theme }>(({ theme }) => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
}));

const MenuItem = styled('li')<{ theme: Theme }>(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.gutters.base * 1,
}));

// TODO - Extract?
const StyledLink = styled('a')<{ theme: Theme }>(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'inherit',
  ...theme.typography.meta.menuItem,
  fontFamily: theme.typography.fontFamily,
}));

export const MenuItems: React.FunctionComponent<MenuItemsProps> = ({ menuItems = [], toggleMenu }) => {
  console.log('menuItems', menuItems);
  return (
    <Wrapper
      {
        ...{
          //  variants: menuItemsVariants
        }
      }
    >
      <Link {...{ to: '/' }}>
        <Logo {...{ alt: 'The Sashka Studios Logo', maxWidth: '100px' }} />
      </Link>
      <Typography>Collections</Typography>
      <StyledList>
        {menuItems.map((item, ind) => (
          <MenuItem
            {...{
              key: item.key,
              // variants: menuItemVariants
            }}
          >
            <Link {...{ to: `/collections/${item.handle}`, onClick: toggleMenu }}>
              <Typography {...{ variant: 'body1' }}>{item.text}</Typography>

              {/* <StyledLink {...{ onClick: toggleMenu }}>{item.text}</StyledLink> */}
            </Link>
          </MenuItem>
        ))}
      </StyledList>
    </Wrapper>
  );
};

export default MenuItems;
