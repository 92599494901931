import axios, { AxiosResponse } from 'axios';

import { sashkaAPI } from '../../src/config/envs';

const client = axios.create({
  baseURL: sashkaAPI,
  headers: {
    'Content-Type': 'application/json',
  },
});

export interface ProductSearchResult {
  available: boolean;
  body: string;
  compare_at_price_max: string;
  compare_at_price_min: string;
  handle: string;
  id: number;
  image: string;
  price: string;
  price_max: string;
  price_min: string;
  tags: string[];
  title: string;
  type: string;
  url: string;
  variants: Array<{
    available: boolean;
    compare_at_price: string;
    id: number;
    image: string;
    price: string;
    title: string;
    url: string;
  }>;
  vendor: string;
}

export const searchProducts = (query: string) => {
  return client.get<ProductSearchResult[]>('/searchShopifyProduct', { params: { q: query } });
};
