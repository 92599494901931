import * as React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@sashka/ui-lib';
import Link from 'next/link';
import { Theme } from '@sashka/theme';
import Logo from '../Logo/Logo';

interface FooterProps {}

const PoweredBy = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Footer: React.FunctionComponent<FooterProps> = ({}) => {
  return (
    <div>
      <PoweredBy>
        <Typography {...{ variant: 'label' }}>© {new Date().getFullYear()}, Powered By - </Typography>
        <a {...{ href: 'https://thesashka.com', target: '_blank', rel: 'noopener noreferrer' }}>
          <Logo {...{ alt: 'The Sashka Studios Logo', maxWidth: 100 }} />
        </a>
      </PoweredBy>
    </div>
  );
};

export default Footer;
