interface EnvsConfig {
  shopName: string;
  shopifyAccessToken: string;
  sashkaAPI: string;
}

if (!process.env.SHOP_NAME) {
  throw new Error(`no SHOP_NAME in your env file`);
}

if (!process.env.SHOPIFY_ACCESS_TOKEN) {
  throw new Error(`no SHOPIFY_ACCESS_TOKEN in your env file`);
}

if (!process.env.SASHKA_API) {
  throw new Error(`no SASHKA_API in your env file`);
}

export const shopName = process.env.SHOP_NAME;
export const shopifyAccessToken = process.env.SHOPIFY_ACCESS_TOKEN;
export const sashkaAPI = process.env.SASHKA_API;

export const envsConfig: EnvsConfig = {
  shopName,
  shopifyAccessToken,
  sashkaAPI,
};
