import * as React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Theme } from '@sashka/theme';

interface DrawerProps {
  // interface ???
  isOpen: boolean;
}

const backdropAnimation = {
  open: {
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  },
  closed: {
    opacity: 0,
    transition: {
      delay: 0.15,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

const drawerAnimation = {
  open: () => ({
    left: 0,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    left: '-100%',
    transition: {
      delay: 0.15,
      type: 'spring',
      stiffness: 20,
      damping: 2,
    },
  },
};

const DrawerWrapper = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  maxWidth: 250,
  width: 'auto',
  height: '100vh',
  position: 'relative',
  left: '-100%',
  backgroundColor: theme.palette.background.gamma,
}));

const Backdrop = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: -1,
  opacity: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.25)',
  filter: 'blur(50px)',
}));

export const Drawer: React.FunctionComponent<DrawerProps> = ({ children, isOpen }) => {
  console.log('isOpen', isOpen);
  return (
    <>
      <Backdrop {...{ animate: isOpen ? 'open' : 'closed', variants: backdropAnimation }} />

      <DrawerWrapper {...{ animate: isOpen ? 'open' : 'closed', variants: drawerAnimation }}>{children}</DrawerWrapper>
    </>
  );
};

export default Drawer;
