import * as React from 'react';

import styled from '@emotion/styled';
import { Theme } from '@sashka/theme';

interface ButtonProps {
  disabled?: boolean;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: (e: any) => void;
  variant?: 'contained' | 'text' | 'outline' | 'action';
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  children: React.ReactNode;
  color?: keyof Theme['palette'];
  styleOverride?: React.CSSProperties;
}

const StyledButton = styled('button')<{
  theme?: Theme;
  styleOverride?: ButtonProps['styleOverride'];
  disabled?: boolean;
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
}>(
  ({ theme }) => ({
    '&:hover': {
      opacity: 0.7,
    },
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: theme.gutters.base,
    whiteSpace: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 7px 8px 0 rgba(48, 146, 255, 0.16)',
    height: '100%',
    borderRadius: theme.gutters.base,
    fontsize: theme.typography.meta.body1.fontSize,
    padding: `${theme.gutters.base * 1}px ${theme.gutters.base * 3}px`,
    textTransform: 'uppercase',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in',
    '&:focus': {
      outline: 'none',
    },
  }),
  ({ theme, variant = 'contained', color }) => ({
    ...{},
    ...(variant === 'contained' && {
      border: 'none',
      backgroundColor: theme.palette.main.cta,
      //   backgroundImage: 'linear-gradient(to left, #3500ff, #544efb)',
      color: 'white',
    }),
    ...(variant === 'text' && {
      border: 'none',
      backgroundColor: 'transparent',
      backgroundImage: 'none',
      boxShadow: 'none',
      color: (color && theme.palette[color]) || theme.palette.main.cta,
      padding: 0,
      fontWeight: theme.typography.meta.body1.fontWeight,
      //   fontWeight: theme.fonts.weight.listItem,
    }),
    ...(variant === 'outline' && {
      border: `1px solid #fff`,
      borderRadius: theme.gutters.base,
      backgroundColor: 'rgba(250, 248, 248, 0.2)',
      backgroundImage: 'none',
      boxShadow: 'none',
      color: theme.palette.text.contrast,
    }),
    ...(variant === 'action' && {
      border: 'none',
      color: theme.palette.text.contrast,
      backgroundColor: `${theme.palette.main.cta}0d`,
      boxShadow: 'none',
      padding: `${theme.gutters.base * 1.2}px ${theme.gutters.base * 3}px`,
      fontWeight: theme.typography.meta.body1.fontWeight,
    }),
  }),
  ({ theme, disabled }) =>
    disabled && {
      cursor: 'not-allowed',
      opacity: 0.2,
      '&:hover': {
        opacity: 0.2,
      },
    },
  ({ styleOverride }) => styleOverride && { ...styleOverride },
);

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, leftIcon, rightIcon, ...rest }, ref) => {
    return (
      <StyledButton {...rest} {...{ ref }}>
        {leftIcon && React.cloneElement(leftIcon)}
        {children}
        {rightIcon && React.cloneElement(rightIcon)}
      </StyledButton>
    );
  },
);

export default Button;
